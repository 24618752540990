import { Component, OnInit, Input, ViewChild, EventEmitter } from '@angular/core';
import {Router, Event, NavigationStart, NavigationEnd, NavigationError, RouterModule, ActivatedRoute} from "@angular/router";
import { Location } from "@angular/common";
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

//import { HomeComponent } from '../../home/home.component';
import { AuthenticationService } from '@app/_services';
import { User, Role } from '@app/_models';
import { MetricsService } from '../../metrics.service';
import { environment } from '../../../environments/environment';
import { CustomService } from '../../_services/custom.service';

declare var $: any;

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

	//@ViewChild(HomeComponent, { static: true }) homeComponent: HomeComponent;
	@Input() headerObj: any;
	user: any;
  	executeAction: EventEmitter<any> = new EventEmitter();

  	currPath: any;
	currMetrics: any;
	currHost: any;  
	shareLink: string;
  	shareTxt: string;
  	closeResult: string;
  	changepwd_form: FormGroup;
	chgPwdMsgshow: boolean = false;
	chgPwdMsg: string;

  	selMetrics = {'name':'1_OverallDashboard','hid':2, 'displayname':'Overall Dashboard'};
	metrics = {
		'Dashboard':{'name':'1_OverallDashboard','hid':2, 'displayname':'Overall Dashboard'},
		'LifetimeMetrics':{'name':'LifetimeMetrics','hid':3, 'displayname':'Lifetime Metrics'},
		'MonthlyMetrics':{'name':'MonthlyMetrics','hid':4, 'displayname':'Monthly Metrics'},
		'DailyMetrics':{'name':'DailyMetrics','hid':5, 'displayname':'Daily Metrics'},
		'MonthlySubs':{'name':'MonthlySubs','hid':6, 'displayname':'Monthly Subs'},
		'ActiveUsers':{'name':'ActiveUsers','hid':7, 'displayname':'Active Users'},
		'NewSubs':{'name':'NewSubs','hid':8, 'displayname':'New Subs'},
		'TotalSubs':{'name':'TotalSubs','hid':9, 'displayname':'Total Subs'},
		'EngagementDetail':{'name':'EngagementDetail','hid':3, 'displayname':'Engagement'},
		'Month1Retention':{'name':'Month1Retention','hid':4, 'displayname':'Month 1 Retention'}
	}

  	

	constructor(
		private authService: AuthenticationService,
		private router: Router,
    	private mService: MetricsService,
    	private location: Location,
    	private modalService: NgbModal,
    	private fb: FormBuilder,
    	private http: HttpClient,
    	private cService: CustomService
	) {
		//this.cService.onLoadScript(); 

		this.authService.user.subscribe(x => this.user = x);
		this.currPath = this.location.path().slice(1);
	    this.currMetrics = this.location.path().slice(1);
	  	if(this.currMetrics == '')  {
	  		this.currMetrics = 'Dashboard';
	  	}

	  	mService.changeEmitted$.subscribe(
        text => {
          if(this.currMetrics != "") {
            if((text in this.metrics)) {             
              this.shareLink = window.location.href;
            } else {
              this.shareLink = '';
            }
          }  
        });

        this.changepwd_form = fb.group({
	      'newpassword1': [null, Validators.required],
	      'newpassword2': [null, Validators.required]
	    });
	}

	ngOnInit(): void {
		//console.log(this.user);
	}

	get isAdmin() {
		return this.user && this.user.role === Role.Admin;
	}

	get isDararoom() {
		return this.user && this.user.role === Role.Dataroom;
	}

	tableauProcess(type) {
		this.mService.MetricsType(type);
		this.mService.sendReportEvent();
		if(type == 'pause') {
			document.querySelector('[data-dom-for="resume"]').classList.remove('hidden');
			document.querySelector('[data-dom-for="pause"]').classList.add('hidden');
		} else if(type == 'resume') {
			document.querySelector('[data-dom-for="pause"]').classList.remove('hidden');
			document.querySelector('[data-dom-for="resume"]').classList.add('hidden');
		}
		//this.executeAction.emit();
    }

	open(content) {
		this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			//this.closeResult = `Dismissed`;
		});
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return  `with: ${reason}`;
		}
	}

	toggleMenu() {
		$('.app').toggleClass('sidenav-toggled');
		//$("#wrapper").toggleClass("toggled");
	}

	copyText(val: string){
		let selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = val;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);
	}

	get f() { return this.changepwd_form.controls; }

	submitForm() {
		// stop here if form is invalid
        if (this.changepwd_form.invalid) {
            return;
        }
        this.http.post<any>(environment.apiUrl, { 'userid': this.user.credentials.user.id, 'newpassword':this.f.newpassword1.value, 'ptype':'changepassword' }).subscribe(data => {
        	this.chgPwdMsgshow = true;
			this.chgPwdMsg = "Password changed successfully";
		    //this.closebutton.nativeElement.click();
		    this.changepwd_form.reset();
		});
	};

	logout(){
		this.authService.logout();
		this.router.navigateByUrl('/Login');
	}

	redirectUrl(path) {
		this.router.navigate([path]).then(
			page => { window.location.reload(); }
		);
	}

}
