import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from  '@angular/forms';
import { Router, ActivatedRoute } from  '@angular/router';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
//import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';

import { AuthenticationService } from '@app/_services';
import { User, Role } from '@app/_models';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  user: any;
	constructor(
        private authService: AuthenticationService, 
        private route: ActivatedRoute, 
        private router: Router, 
        private formBuilder: FormBuilder,
        private toastr: ToastrService,
    ) { 
		// redirect to home if already logged in
        if (this.authService.userValue) { 
          this.authService.user.subscribe(x => this.user = x);
          if(this.user && this.user.role === Role.Dataroom) {
            this.router.navigate(['/Dataroom']);
          } else {
            this.router.navigate(['/Home']);
          }
          
        }
	}

	loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error: boolean = false;
    errorMsg = '';

	ngOnInit(): void {

        this.loginForm = new FormGroup({
          username: new FormControl('', [
            Validators.required
          ]),
          password: new FormControl('', [
            Validators.required
          ])
        });

		/*this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });*/
        this.returnUrl = '/';		
        if(this.route.snapshot.queryParams['returnUrl'] !== undefined) {
            this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
        }
	}

  	//get formControls() { return this.authForm.controls; }
  	get f() { return this.loginForm.controls; }
	
  	onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }
        this.error = false;
        this.loading = true;
        this.authService.login(this.f.username.value, this.f.password.value, 'login')
            .pipe(first())
            .subscribe(
                data => {
                	if(data == "error") {
                		this.error = true;
                    	this.loading = false;
                		this.errorMsg = 'Login failed; Invalid userID or password';
                        //this.toastr.success('Setting saved successfully', 'Success');
                        this.toastr.error('Login failed, Invalid username or password', 'Error:', {
                          timeOut: 3000,
                          positionClass: 'toast-top-full-width',
                        });
                	} else {
                        if(this.authService.userValue.role == Role.Dataroom) {
                          this.returnUrl = "/Dataroom";
                        }
                        this.router.navigate([this.returnUrl]);
                	}
                    
                },
                error => {
                    this.error = true;
                    this.loading = false;
                    this.errorMsg = 'Login failed, Invalid userID or password';
                });
    }

    forgetFrom() {
        document.querySelector('.login-box').classList.toggle('flipped');
        /*$('.login-content [data-toggle="flip"]').click(function() {
          $('.login-box').toggleClass('flipped');
          return false;
      });*/
    }

}
