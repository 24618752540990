import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from "@angular/common";
import { FormControl, FormBuilder, FormGroup, Validators } from  '@angular/forms';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import {Router, Event, NavigationStart, NavigationEnd, NavigationError, RouterModule, ActivatedRoute, Params} from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { filter, map } from 'rxjs/operators';
import { of, Observable  } from 'rxjs';
import { NgWizardConfig, NgWizardService, StepChangedArgs, StepValidationArgs, STEP_STATE, THEME } from 'ng-wizard';
//import '~ng-wizard/themes/ng_wizard.min.css';
import { AuthenticationService } from '@app/_services';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit, OnDestroy {
	settingForm: FormGroup;
	queryRes = [];
	currPath:any;	
    paramsSub:any;
    pgName:any;
    currTitle:any;
    paramID:any;
    isLoading = false;
    pgIcon = 'fa-wrench';
    user:any;

    stepStates = {
	    normal: STEP_STATE.normal,
	    disabled: STEP_STATE.disabled,
	    error: STEP_STATE.error,
	    hidden: STEP_STATE.hidden
	  };
	 
	  config: NgWizardConfig = {
	    selected: 0,
	    theme: THEME.dots,
	    toolbarSettings: {
	      toolbarExtraButtons: [
	        { text: 'Finish', class: 'btn btn-success', event: () => { this.onSubmit(); } }
	      ],
	    }
	  };

	constructor(
		private formBuilder: FormBuilder,
    	private http: HttpClient,
    	private toastr: ToastrService,
  		private location: Location,
    	private router: Router,
    	private actRouter: ActivatedRoute,
    	private ngWizardService: NgWizardService,
    	private authService: AuthenticationService
	) { 
		this.authService.user.subscribe(x => this.user = x);
		
		this.paramsSub = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(event => {
        	if("url" in event) {
        		this.actRouter.firstChild.params.subscribe((params: Params) => {
				   this.paramID = params.id;
				});
				/*if("_value" in this.actRouter.firstChild.url) {
					this.currPath = this.actRouter.firstChild.url._value[0].path;
				}*/
        		if(event.url == '/Admin') {
        			event.url = '/Admin/Settings';
        		}
        		this.currPath = event.url.replace('/Admin/','');
        		this.currPath = this.currPath.replace('/'+this.paramID,'');
        		this.onInit(this.paramID);
        	}        	
        });	    
	}

	ngOnInit(): void {
		
		//this.router.events.subscribe((url:any) => console.log(url));
      	//console.log(this.router.url);  // to print only path eg:"/login"
		/*this.paramsSub = this.router.events.subscribe(event => {
			console.log('Router event: ', event);
			//this.onInit();
			//this.currPath = this.location.path().replace('/Admin/','');
			//alert(this.currPath);
		})*/
		
				
	}

	onInit(id) {
		switch(this.currPath) { 
		   case 'Settings': { 
		   		this.currTitle = 'Settings';
		   		this.pgIcon = 'fa-wrench'
		      //rshost rsport rsdbname rsuser rspassword thost tuser tpassword sess_time sess_stay
				this.settingForm = this.formBuilder.group({
					rshost: ['', Validators.required],
					rsport: ['', Validators.required],
					rsdbname: ['', Validators.required],
					rsuser: ['', Validators.required],
					rspassword: [''],
					thost: ['', Validators.required],
					tuser: ['', Validators.required],
					tpassword: [''],
					sess_time: ['', Validators.required],
					sess_stay: ['', Validators.required]
				});
				this.getSettings();
		      break; 
		   } 
		   case 'Users': { 
		   		this.currTitle = 'Users';
		   		this.pgIcon = 'fa-user'
		      this.getUsers();
		      break; 
		   } 
		   case 'Groups': { 
		   		this.currTitle = 'Groups';
		   		this.pgIcon = 'fa-users'
		      this.getGroups();
		      break; 
		   } 
		   case 'Projects': { 
		   		//this.getThumb();
		   		this.currTitle = 'Projects';
		   		this.pgIcon = 'fa-folder-open-o'
		      this.getProjects();
		      break; 
		   } 
		   case 'Workbooks': { 
		   		this.currTitle = 'Workbooks';
		   		this.pgIcon = 'fa-book'
		      this.getWorkbooks(id);
		      break; 
		   } 
		   case 'Views': {
		   		this.currTitle = 'Views'; 
		   		this.pgIcon = 'fa-line-chart'
		      	this.getViews(id);
		      break; 
		   } 
		   default: { 
		      //statements; 
		      break; 
		   } 
		} 
	}

	get f() { return this.settingForm.controls; }

	getSettings() {
        this.queryRes = [];
        this.http.post<any>(environment.configApiUrl, { 'ptype':'getconfig' }).subscribe(data => {        
       		this.settingForm = this.formBuilder.group({
              rshost: [data.redshift.host],
              rsport: [data.redshift.port],
              rsdbname: [data.redshift.dbname],
              rsuser: [data.redshift.user],
              rspassword: [],
              thost: [data.tableau.host],
              tuser: [data.tableau.user],
              tpassword: [],
              sess_time: [data.site.sessionTime],
              sess_stay: [data.site.sessionStay]
            });
		});
	};

	getUsers() {
		this.isLoading = true;
        this.queryRes = [];
        this.http.post<any>(environment.apiUrl, { 'ptype':'getUsers' }).subscribe(data => {
       		if(data.status != 'error') {
       			this.queryRes = data.users;
       		}
       		this.isLoading = false;
		});
	};

	getProjects() {
		this.isLoading = true;
        this.queryRes = []; //getProjects
        this.http.post<any>(environment.apiUrl, { 'ptype':'getProjects' }).subscribe(data => {
       		if(data.status != 'error') {
       			this.queryRes = data.projects;
       		}
       		this.isLoading = false;
		});
	};

	getWorkbooks(pid) {
		//console.log(this.getThumb());
        this.queryRes = []; //getWorkbooks
        this.isLoading = true;
        this.http.post<any>(environment.apiUrl, { 'pid':pid, 'ptype':'getWorkbooks' }).subscribe(data => {
       		if(data.status != 'error') {
       			//this.queryRes = data.workbooks;
       			//this.queryRes = data.workbooks.filter(xx => xx.project.id = pid);
       			//const dat:any = {'project':{'id':0}};
       			const dat:any = Object.assign({}, data.workbooks.workbook);
       			this.queryRes = Object.values(dat).filter(dat => dat['project'].id === pid);

       		}
       		this.isLoading = false;
		});
	};	

	getViews(wid) {;
		this.isLoading = true;
        this.queryRes = []; //getViews
        this.http.post<any>(environment.apiUrl, { 'wid':wid, 'ptype':'getViews' }).subscribe(data => {
        	if(data.status != 'error') {
       			this.queryRes = data.views;
       		}
       		this.isLoading = false;
		});
	};	

	getGroups() {
		this.isLoading = true;
        this.queryRes = [];
        this.http.post<any>(environment.apiUrl, { 'ptype':'getGroups' }).subscribe(data => {
        	if(data.status != 'error') {
       			this.queryRes = data.groups;
       		}
       		this.isLoading = false;
		});
	};

	onSubmit() {
		// stop here if form is invalid
		if (this.settingForm.invalid) {
			return;
		}
		this.settingForm.value['ptype'] = 'saveconfig';
		this.http.post<any>(environment.configApiUrl, this.settingForm.value).subscribe(data => {
			this.toastr.success('Setting saved successfully', 'Success');
		});
	};



	ngOnDestroy() {
		this.paramsSub.unsubscribe();
	}


	showPreviousStep(event?: Event) {
		this.ngWizardService.previous();
	}

	showNextStep(event?: Event) {
		this.ngWizardService.next();
	}

	resetWizard(event?: Event) {
		this.ngWizardService.reset();
	}

	setTheme(theme: THEME) {
		this.ngWizardService.theme(theme);
	}

	stepChanged(args: StepChangedArgs) {
		//console.log(args.step);
		//console.log('step')
	}

	isValidTypeBoolean: boolean = true;

	isValidFunctionReturnsBoolean(args: StepValidationArgs) {
		return true;
	}

	isValidFunctionReturnsObservable(args: StepValidationArgs) {
		return of(true);
	}


}
