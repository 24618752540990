<main class="app-content">
    <div class="app-title">
       <div>
          <h1><i class="fa fa-code"></i> Analytics</h1>
       </div>
    </div>
    <div class="row">
       <div class="col-md-12">
          <div class="tile">
             <div class="embed-responsive">
                <form [formGroup]="queryForm" (ngSubmit)="onSubmit()">
                    <h6>Q1: What is the time period 
                        <select formControlName="opt1">
                           <option *ngFor="let opt of selOpt.opt1" [ngValue]="opt.id">{{opt.name}}</option>
                            <!-- <option value="CY">Current Year</option>
                            <option value="LY">Last Year</option>
                            <option value="YoY">YoY</option>
                            <option value="MoM">MoM</option>
                            <option value="QoQ">QoQ</option> -->
                        </select>
                        metric of 
                        <select formControlName="opt2">                            
                           <option *ngFor="let opt of selOpt.opt2" [ngValue]="opt.id">{{opt.name}}</option>
                        </select>
                        for filter                     
                        <select formControlName="opt3">                            
                           <option *ngFor="let opt of selOpt.opt3" [ngValue]="opt.id">{{opt.name}}</option>
                        </select>
                        ? &nbsp;&nbsp;&nbsp;
                        <button type="submit" class="btn btn-primary" id="btnsubmit" style="margin-top:10px;"><span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" *ngIf="loading"></span> Submit</button>&nbsp;&nbsp;
                        <button *ngIf="loading" type="button" class="btn btn-primary" (click)="onSubmitCancel();" style="margin-top:10px;">Cancel</button>
                    </h6>
                    <!-- <div>
                        <button type="submit" class="btn btn-primary" id="btnsubmit" style="margin-top:10px;"><span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" *ngIf="loading"></span> Submit</button>&nbsp;&nbsp;
                        <button *ngIf="loading" type="button" class="btn btn-primary" (click)="onSubmitCancel();" style="margin-top:10px;">Cancel</button>
                    </div> -->
                </form>
                <form [formGroup]="queryForm" (ngSubmit)="filterReport()">
                    <h6>Q2: Show me a chart of 
                        <select formControlName="opt4">
                            <!-- <option value="1_OverallDashboard">Overall Dashboard</option> -->                            
                           <option *ngFor="let opt of selOpt.opt4" [ngValue]="opt.id">{{opt.name}}</option>
                        </select> 
                        by 
                        <select formControlName="opt5">
                            <option value="all">All</option>
                            <option value="01">January</option>
                            <option value="02">February</option>
                            <option value="03">March</option>
                            <option value="04">April</option>
                            <option value="05">May</option>
                            <option value="06">June</option>
                            <option value="07">July</option>
                            <option value="08">Augest</option>
                            <option value="09">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                        </select> 
                        for 
                        <select formControlName="opt6">
                            <option value="2021">2021</option>
                            <option value="2020">2020</option>
                            <option value="2019">2019</option>
                            <option value="2018">2018</option>
                            <option value="2017">2017</option>
                        </select>
                        ? &nbsp;&nbsp;&nbsp;
                        <button type="submit" class="btn btn-primary" id="btnsubmit" style="margin-top:10px;"><span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" *ngIf="loading"></span> Submit</button>&nbsp;&nbsp;
                        <button *ngIf="loading" type="button" class="btn btn-primary" (click)="onSubmitCancel();" style="margin-top:10px;">Cancel</button>
                    </h6>
                    <!-- <div>
                        <button type="submit" class="btn btn-primary" id="btnsubmit" style="margin-top:10px;"><span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" *ngIf="loading"></span> Submit</button>&nbsp;&nbsp;
                        <button *ngIf="loading" type="button" class="btn btn-primary" (click)="onSubmitCancel();" style="margin-top:10px;">Cancel</button>
                    </div> -->
                </form>
                <!-- <form [formGroup]="queryForm" (ngSubmit)="onSubmit()">
                <textarea class="form-control" id="querytxt" name="querytxt" rows="3" formControlName="querytxt" required="required"></textarea>
                <button type="submit" class="btn btn-primary" id="btnsubmit" style="margin-top:10px;"><span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" *ngIf="loading"></span> Submit</button>&nbsp;&nbsp;
                <button *ngIf="loading" type="button" class="btn btn-primary" (click)="onSubmitCancel();" style="margin-top:10px;">Cancel</button>
                </form> -->
                <div *ngIf="sqlTxt != ''" class="alert alert-success" role="alert" style="margin-bottom:0px; margin-top:10px;"><strong>Query:</strong> {{sqlTxt}}</div>
                <div *ngIf="errorMsg != ''" class="alert alert-danger" role="alert" style="margin-bottom:0px; margin-top:10px;">{{errorMsg}}</div>
             </div>
          </div>
       </div>
    </div>

    <div id="loader" class="fa-3x text-center text-black" *ngIf="loading">
      <i class="fa fa-spinner fa-spin fa-2x fa-fw"></i>
    </div>

    <div class="row" *ngIf="selSection == 'q1' && queryRes.length > 0">
      <div class="col-md-12">
         <div class="tile">
            <div class="tile-body">
               <div class="row">
                  <div class="col-md-6"><strong>Total:</strong> {{queryRes.length}} Rows</div>
                  <div class="col-md-6" style="text-align:right;">
                     <ul style="list-style-type:none;">
                        <li class="dropdown">
                           <a href="#" data-toggle="dropdown" aria-label="Open Profile Menu" style="text-decoration:none;">Export&nbsp; <i class="fa fa-sort-desc" style="margin-top:-5px;"></i></a>
                           <ul class="dropdown-menu settings-menu dropdown-menu-right" style="width:150px;">
                              <li><a class="dropdown-item" href="javascript://" (click)="exportD(confirm,'csv')">CSV</a></li>
                              <li><a class="dropdown-item" href="javascript://" (click)="exportD(confirm,'xlsx')">XLSX</a></li>
                           </ul>
                        </li>
                     </ul>
                  </div>
               </div>
               <div class="table-responsive"> <!-- style="height:200px !important; " --> <!-- style="overflow:auto; height: calc(100vh - 225px);" -->
                  <div class="gridcontainer" >
                     <table class="table table-hover table-bordered table-sm" id="">
                        <thead>
                           <tr>
                              <th scope="col" *ngFor="let head of _object.keys(queryRes[0]); let i = index">{{head}}</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr *ngFor="let qres of queryRes; index as i">
                              <td *ngFor="let key of _object.keys(qres); index as j">{{ qres[key] }} </td>
                           </tr>
                        </tbody>
                     </table>                     
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <div class="row" *ngIf="selSection == 'q2'">
      <div class="col-md-12">
         <div class="tile" style="padding: 2px;">
            <!-- <h3 class="tile-title">Overall Metrics - September 2020</h3> -->
            <div class="embed-responsive">
               <section style="display: none;" id="no-views" class="hidden">
                  <div class="container sidebar-container my-3">
                     <h5>No views found on the Tableau Server. Please ensure your user has permission to see the views.</h5>
                  </div>
               </section>
               <section class="bg-white content-wrapper">
                  <div id="loader" class="hidden text-center" style="padding: 30px;">
                     <i class="fa fa-spinner fa-spin fa-4x fa-fw"></i>
                  </div>
                  <div class="viz-wrapper py-3" id="viz-wrapper" style=" height: 850px;">
                     <div id="tableauViz" style="width: 100%;" class="h-100 d-inline-block"></div>
                  </div>
               </section>
            </div>
         </div>
      </div>
   </div>
   <!-- <google-chart id="columnChart" [data]="columnChart"></google-chart> -->
   <div class="row" *ngIf="selSection == 'q1' && queryRes.length > 0">
      <div #containerRef class="col-md-12 resW">
         <ngx-charts-bar-vertical
         (window:resize)="onResize()"
         [view]="[containerRef.offsetWidth, containerRef.offsetHeight]"
         [scheme]="colorScheme"
         [results]="chartData"
         [gradient]="gradient"
         [xAxis]="showXAxis"
         [yAxis]="showYAxis"
         [legend]="showLegend"
         [showXAxisLabel]="showXAxisLabel"
         [showYAxisLabel]="showYAxisLabel"
         [xAxisLabel]="xAxisLabel"
         [yAxisLabel]="yAxisLabel"
         (select)="onSelect($event)">
         <ng-template #tooltipTemplate let-model="model">
            <div style="padding:5px; font-weight: 700; font-size: 14px;">
               <div>{{ model.extra.type }}: {{ model.name }}</div>
               <div>Value: {{ model.value }}</div>
               <div  *ngIf="model.extra.percentage != ''">%: {{ model.extra.percentage }}</div>
            </div>
         </ng-template>
         </ngx-charts-bar-vertical>
      </div>
      
   </div>

   <div class="row mt-2" *ngIf="selSection == 'q1' && queryRes.length > 0">
      <div class="col-md-12">
         <div class="tile">
            <div class="tile-body">
               <div class="row">
                  <div class="col-md-6"><strong>Recommended Questions</strong></div>
                  <div class="col-md-6" style="text-align:right;">
                     
                  </div>
               </div>
               <div class="table-responsive">
                  <div class="gridcontainer" style="padding-top: 10px;">
                     <h6 *ngFor="let opt of selOpt.opt2; let i = index;">
                        <span *ngIf="opt.id != queryForm.value.opt2"><a href="javascript://" (click)="recommend(filterData(queryForm.value.opt1)[0].id, opt.id,'AG')">
                           Q{{(i)}}: What is the time period {{filterData(queryForm.value.opt1)[0].name}} metric of {{opt.name}} for filter aggregate?</a>
                        </span>
                     </h6>             
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <div class="row" *ngIf="selSection == 'q1'">
      <div #containerRef2 class="col-md-4" *ngFor="let opt of selOpt.opt2; let i = index">
         {{opt.name}}
      </div>
   </div>
   
</main>