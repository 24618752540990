import { Injectable, EventEmitter } from '@angular/core';
//import { Subject }    from 'rxjs/Subject';
import { Observable, Subject } from 'rxjs';   
import { Subscription } from 'rxjs/internal/Subscription'; 

@Injectable({
  providedIn: 'root'
})
export class MetricsService {

  	constructor() { }

	private metricsType = new Subject<string>();
	metricsType$ = this.metricsType.asObservable();

	MetricsType(jsonData){
		//console.log(jsonData);
		this.metricsType.next(jsonData);
	}

	// Observable string sources
    private emitChangeSource = new Subject<any>();
    // Observable string streams
    changeEmitted$ = this.emitChangeSource.asObservable();
    // Service message commands
    emitChange(change: any) {
        this.emitChangeSource.next(change);
    }


    private vizExecution = new Subject<any>();
    sendReportEvent() {
      this.vizExecution.next();
    }
    getReportEvent(): Observable<any>{ 
      return this.vizExecution.asObservable();
    }

    //Redshift Call
    invokeRSFunction = new EventEmitter();    
    rsVars: Subscription;

    onRSShowTable(sitem:any) {    
      this.invokeRSFunction.emit(sitem);    
    }

    //Redshift Schema Table Menu
    invokeRSMenuFunction = new EventEmitter();    
    rsMenuVars: Subscription;

    onRSShowMenu(sitem:any) {    
      this.invokeRSMenuFunction.emit(sitem);
    }



}
