import { Component, OnInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy, Inject, AfterViewInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import {Router, Event, NavigationStart, NavigationEnd, NavigationError, RouterModule, ActivatedRoute} from "@angular/router";
import {NgbModal, ModalDismissReasons, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { MetricsService } from '../../metrics.service';
import { CustomService } from '../../_services/custom.service';
import { ShortcutInput, ShortcutEventOutput, KeyboardShortcutsComponent, AllowIn } from "ng-keyboard-shortcuts"; 

import alasql from 'alasql';
import * as XLSX from 'xlsx';
alasql['utils'].isBrowserify = false;
alasql['utils'].global.XLSX = XLSX;
//declare var XLSX: any;
declare var $: any;

@Component({
  selector: 'app-sqlquery',
  templateUrl: './sqlquery.component.html',
  styleUrls: ['./sqlquery.component.css'],
  //changeDetection: ChangeDetectionStrategy.OnPush
})

export class SqlqueryComponent implements OnInit, OnDestroy {

    title = 'Analytics - Sql Query';
    modalRef: any;
	  queryForm: FormGroup;
	  loading = false;
    submitted = false;
    returnUrl: string;
    error: boolean = false;
    errorMsg = '';
    sqlTxt = '';
    showQueryBox: boolean = true;
    queryRes: any = []; 
    _object = Object;  
    inTableheight = '50vh';
    exportType: any;
    postSubmit: any;
    paramsSub:any;
    shortcuts: ShortcutInput[] = [];  

  constructor(
    private formBuilder: FormBuilder, 
    private http: HttpClient,
    private modalService: NgbModal,
    private mService: MetricsService,
    private titleService: Title,
    private router: Router,
    private actRouter: ActivatedRoute,
    private cService: CustomService

  ) {

    this.cService.onLoadScript(); 

    this.paramsSub = this.actRouter.params.subscribe(params => {
      //console.log(params);
      if(params.type != undefined && params.schema != undefined && params.table != undefined) {
        this.showTable({});
      }
    });

    //webGlObject.init();

  }

  ngOnInit(): void {
    this.titleService.setTitle( this.title );

    this.shortcuts.push( 
      {  
        key: "cmd + enter",  
        allowIn: [AllowIn.Textarea, AllowIn.Input],
        command: (output: ShortcutEventOutput) => this.test(),  
        preventDefault: true  
      }  
    ); 
    
    /*this.router.navigate(['/SQLQuery'], {
      queryParams: {refresh: new Date().getTime()}
    }).then(() => {
      //window.location.reload();
    });
    this.router.navigateByUrl('/SQLQuery', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/SQLQuery']);
    }); */
    /*this.router.navigate(['/SQLQuery'])
    .then(() => {
      //window.location.reload();
    });*/
    
    if (this.mService.rsVars==undefined) {    
      this.mService.rsVars = this.mService.    
      invokeRSFunction.subscribe((sitem:any) => {
        this.showTable(sitem);    
      });    
    } 

  	this.queryForm = new FormGroup({
      querytxt: new FormControl('', [
        Validators.required
      ])
    });
  }

  test() {
    $('#btnsubmit').trigger('click');
  }

  ngOnDestroy() {
    this.paramsSub.unsubscribe();
  }

  showTable(row: any) {
    row = {'stype':this.actRouter.snapshot.params.type,'table_schema':this.actRouter.snapshot.params.schema,'table_name':this.actRouter.snapshot.params.table}
    
    if(row.stype == 'reset') {
      this.showQueryBox = true;
      this.queryRes = [];
      this.sqlTxt = '';
      this.errorMsg = '';
      this.inTableheight = '50vh';
    } else {
      this.showQueryBox = false;
      this.queryRes = [];
      this.sqlTxt = '';
      this.errorMsg = '';
      this.inTableheight = '85vh'
      this.loading = true;
      this.http.post<any>(environment.rsApiUrl, { 'tblschema':row.table_schema, 'tblname':row.table_name, 'ptype':row.stype }).subscribe(data => {
            
        if(data.status == 'success') {
          this.queryRes = data.output;
        } else {
          this.errorMsg = data.error;
        }
        //this.cd.detectChanges();
        //this.cd.markForCheck();
        this.loading = false;
      });
    }
      
    
  }

  get f() { return this.queryForm.controls; }

  onSubmit() {
		// stop here if form is invalid
        if (this.queryForm.invalid) {
            return;
        }
        this.queryRes = [];
        this.sqlTxt = '';
        this.errorMsg = '';

        this.loading = true;
        this.postSubmit = this.http.post<any>(environment.rsApiUrl, { 'querytxt': this.f.querytxt.value,'ptype':'sqlquery' }).subscribe(data => {
        
        this.sqlTxt = this.f.querytxt.value;
		    if(data.status == 'success' ) {
          this.queryForm.reset();
          this.queryRes = data.output;
          //this.queryRes = alasql('SELECT user_id FROM ? ', [data.output] );
          //alasql("SELECT * INTO XLSX('test.xlsx',{headers:true}) FROM ? ",[data.output]);
          //alasql("SELECT * INTO CSV('test.csv',{headers:true}) FROM ?", [data.output]);
          //alasql('SELECT * FROM CSV(?, {asstring:true, headers:true, separator:","})', [file1]);
          /*alasql.promise('SELECT * INTO CSV("my.csv", {asstring:true, headers:true, separator:","}) FROM ?',[data.output])
            .then(function(){
                 console.log('Data saved');
            }).catch(function(err){
                 console.log('Error:', err);
            });*/
          //XLSX.utils.json_to_sheet(data.output);
        } else {
          this.errorMsg = data.error;
        }
        this.loading = false;
        

		});
	};

  onSubmitCancel() {
    this.postSubmit.unsubscribe();
    this.showQueryBox = true;
    this.queryRes = [];
    this.sqlTxt = '';
    this.errorMsg = '';
    this.loading = false;
  }

  exportData(etype) {
    if(etype == 'csv') {
      alasql.promise('SELECT * INTO CSV("export_data.csv", {asstring:true, headers:true, separator:","}) FROM ?',[this.queryRes])
      .then(function(){
           console.log('Data saved');
      }).catch(function(err){
           console.log('Error:', err);
      });
    } else if(etype == 'xlsx') {
      alasql.promise('SELECT * INTO XLSX("export_data.xlsx",{headers:true}) FROM ? ',[this.queryRes])
      .then(function(){
           console.log('Data saved');
      }).catch(function(err){
           console.log('Error:', err);
      });
    } else if(etype == 'txt') {
      alasql.promise('SELECT user_id,first_name INTO TXT("export_data.txt") FROM ? ',[this.queryRes])
      .then(function(){
           console.log('Data saved');
      }).catch(function(err){
           console.log('Error:', err);
      });
    }
  }

  exportD(content, etype) {
    this.exportType = etype;
    this.modalRef = this.modalService.open(content, { centered: true }).result.then((result) => {
      //this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      //this.closeResult = `Dismissed`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }



}
