import { Component, OnInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy, Inject, AfterViewInit, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import {Router, Event, NavigationStart, NavigationEnd, NavigationError, RouterModule, ActivatedRoute} from "@angular/router";
import {NgbModal, ModalDismissReasons, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { MetricsService } from '../../metrics.service';
import { CustomService } from '../../_services/custom.service';
import { AuthenticationService } from '@app/_services';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import * as Tableau from 'tableau-api-js';

import alasql from 'alasql';
declare var $: any;
//import * as moment from 'moment';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.css']
})
export class AnalyticsComponent implements OnInit {
  title = 'Analytics - Analytics';
  modalRef: any;
  queryForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error: boolean = false;
  errorMsg = '';
  sqlTxt = '';
  showQueryBox: boolean = true;
  queryRes: any = []; 
  _object = Object;  
  inTableheight = '50vh';
  exportType: any;
  postSubmit: any;
  paramsSub:any;
	tableauViz: any;
	currMetrics: any;
  user: any;
  selSection: any;
  selMetrics = {'name':'MonthlyMetrics','hid':2, 'displayname':'Monthly Metrics'};
  rqRes = [];
  
  selOpt = {
    'opt1':[{'id':'CY', 'name':'Current Year'},{'id':'LY', 'name':'Last Year'},{'id':'YoY', 'name':'YoY'},{'id':'MoM', 'name':'MoM'},{'id':'QoQ', 'name':'QoQ'}],
    'opt2':[{'id':'NS', 'name':'New Subscribers'},{'id':'TS', 'name':'Total Subscribers'},{'id':'CS', 'name':'Churned Subscribers'},{'id':'RE', 'name':'Revenue'},{'id':'AU', 'name':'Active Users'}],
    'opt3':[{'id':'AG', 'name':'Aggregate'},{'id':'BP', 'name':'By Product'},{'id':'BC', 'name':'By Channel'}],
    'opt4':[{'id':'LifetimeMetrics', 'name':'Lifetime Metrics'},{'id':'MonthlyMetrics', 'name':'Monthly Metrics'},{'id':'DailyMetrics', 'name':'Daily Metrics'},{'id':'EngagementDetail', 'name':'Engagement'},{'id':'Month1Retention', 'name':'Month 1 Retention'}],
  };


  chartData = [];
  view: any[] = [700, 400];
  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = false;
  xAxisLabel = 'Year';
  showYAxisLabel = false;
  yAxisLabel = 'Count';
  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };


  constructor(
    private formBuilder: FormBuilder, 
    private http: HttpClient,
    private modalService: NgbModal,
    private mService: MetricsService,
    private el: ElementRef, 
    private titleService: Title,
    private router: Router,
    private actRouter: ActivatedRoute,
    private cService: CustomService,
    private authService: AuthenticationService, 

  ) {
    this.cService.onLoadScript(); 

    this.authService.user.subscribe(x => this.user = x);

    this.paramsSub = this.actRouter.params.subscribe(params => {
      //console.log(params);
      if(params.type != undefined && params.schema != undefined && params.table != undefined) {
        this.showTable({});
      }
    });

    //webGlObject.init();

  }

  ngOnInit(): void {

    this.titleService.setTitle( this.title );
    //$('#columnChart').hide();
    //$('#columnChart').css('visibility', 'hidden');
    
    
    /*this.router.navigate(['/SQLQuery'], {
      queryParams: {refresh: new Date().getTime()}
    }).then(() => {
      //window.location.reload();
    });
    this.router.navigateByUrl('/SQLQuery', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/SQLQuery']);
    }); */
    /*this.router.navigate(['/SQLQuery'])
    .then(() => {
      //window.location.reload();
    });*/
    
    if (this.mService.rsVars==undefined) {    
      this.mService.rsVars = this.mService.    
      invokeRSFunction.subscribe((sitem:any) => {
        this.showTable(sitem);    
      });    
    } 

  	this.queryForm = new FormGroup({
			opt1: new FormControl('CY'),
			opt2: new FormControl('NS'),
			opt3: new FormControl('AG'),
			opt4: new FormControl('LifetimeMetrics'),
			opt5: new FormControl('all'),
			opt6: new FormControl('2021')
		});
  }

  filterData(val) {
    return this.selOpt.opt1.filter(object => {
      return object.id == val;
    });
  }

  onSelect(event) {
    console.log(event);
  }

  ngOnDestroy() {
    this.paramsSub.unsubscribe();
  }

  onResize() { 
    this.view = [$(".resW").width(), 400];
    console.log(this.view);
  }

  recommend(opt1,opt2,opt3) {
    this.queryForm = new FormGroup({
			opt1: new FormControl(opt1),
			opt2: new FormControl(opt2),
			opt3: new FormControl(opt3),
		});
    this.onSubmit();
  }

  getQuarterStrings(id) {
    var d = new Date();
    // quarter is 0-based here
    var quarter = Math.floor(d.getMonth() / 3),
        year = d.getFullYear();
    quarter -= id;
    
    if(quarter < 0) {
        var yearsChanged = Math.ceil(-quarter / 4);
        year -= yearsChanged;
        // Shift quarter back to a nonnegative number
        quarter += 4 * yearsChanged;
    }

    return "Q" + (quarter + 1) + "_" + year;
}

  showTable(row: any) {
    row = {'stype':this.actRouter.snapshot.params.type,'table_schema':this.actRouter.snapshot.params.schema,'table_name':this.actRouter.snapshot.params.table}
    
    if(row.stype == 'reset') {
      this.showQueryBox = true;
      this.queryRes = [];
      this.sqlTxt = '';
      this.errorMsg = '';
      this.inTableheight = '50vh';
    } else {
      this.showQueryBox = false;
      this.queryRes = [];
      this.sqlTxt = '';
      this.errorMsg = '';
      this.inTableheight = '85vh'
      this.loading = true;
      this.http.post<any>(environment.rsApiUrl, { 'tblschema':row.table_schema, 'tblname':row.table_name, 'ptype':row.stype }).subscribe(data => {
            
        if(data.status == 'success') {
          this.queryRes = data.output;
        } else {
          this.errorMsg = data.error;
        }
        //this.cd.detectChanges();
        //this.cd.markForCheck();
        this.loading = false;
      });
    }
      
    
  }

  get f() { return this.queryForm.controls; }

  onSubmit() {
        
      //$('#columnChart').hide();
      //$('#columnChart').css('visibility', 'hidden');
        this.selSection = 'q1';
		// stop here if form is invalid
        if (this.queryForm.invalid) {
            return;
        }
        //this.f.querytxt.value
        var whr = '';
        var fld = '';
        if(this.f.opt2.value == 'NS') {
          fld = 'new_subs';
        } else if(this.f.opt2.value == 'TS') {
          fld = 'total_subs';
        } else if(this.f.opt2.value == 'AU') {
          fld = 'active_subs';
        }
//         SELECT
// SUM(CASE WHEN date_trunc('qtr',month) = date_trunc('qtr',dateadd(qtr,-1,current_date)) THEN new_subs ELSE 0 END) as Current_Quarter,
// SUM(CASE WHEN date_trunc('qtr',month) = date_trunc('qtr',dateadd(qtr,-2,current_date)) THEN new_subs ELSE 0 END) as Last_Quarter
// FROM analytics.monthly_metrics
        var qry = "";
        var d1 = "";
        var d2 = "";
        if(this.f.opt1.value == 'CY') {
          qry = "select sum("+fld+") as cnt from analytics.monthly_metrics where date_trunc('year',month) = date_trunc('year',current_date)";
          //whr += "date_trunc('year',month) = date_trunc('year',current_date)";
        } else if(this.f.opt1.value == 'LY') {
          qry = "select sum("+fld+") as cnt from analytics.monthly_metrics where date_trunc('year',month) = date_trunc('year', (current_date  - interval '1 year'))";
          //whr += "date_trunc('year',month) = date_trunc('year', (current_date  - interval '1 year'))";
        } else if(this.f.opt1.value == 'YoY') {
          const current = new Date();
          const cyear1 = current.getFullYear();
          const cyear2 = current.getFullYear() - 1;
          d1 = cyear1.toString();
          d2 = cyear2.toString();

          qry = "SELECT SUM(CASE WHEN date_trunc('year', (current_date  - interval '1 year')) = date_trunc('year',month) THEN "+fld+" ELSE 0 END) as Year"+d2.toString()+", SUM(CASE WHEN date_trunc('year',current_date) = date_trunc('year',month) THEN "+fld+" ELSE 0  END) as Year"+d1.toString()+" FROM analytics.monthly_metrics";
          console.log(qry);
        } else if(this.f.opt1.value == 'MoM') {
          const current = new Date();
          current.setMonth(current.getMonth()-1);
          const cyear1 = new Date(current.setMonth(current.getMonth())).getFullYear();
          const m1 = current.toLocaleString('default', { month: 'long' });
          
          current.setMonth(current.getMonth()-1);
          const cyear2 = new Date(current.setMonth(current.getMonth())).getFullYear();
          const m2 = current.toLocaleString('default', { month: 'long' });
          d1 = m1.toLowerCase()+'_'+cyear1;
          d2 = m2.toLowerCase()+'_'+cyear2;
          qry = "SELECT SUM(CASE WHEN date_trunc('month', (current_date  - interval '2 month')) = date_trunc('month',month) THEN "+fld+" ELSE 0 END) as "+m2+"_"+cyear2+", SUM(CASE WHEN date_trunc('month',(current_date  - interval '1 month')) = date_trunc('month',month) THEN "+fld+" ELSE 0  END) as "+m1+"_"+cyear1+" FROM analytics.monthly_metrics";
          //whr += "date_trunc('year',month) = date_trunc('year', (current_date  - interval '1 year'))";
        } else if(this.f.opt1.value == 'QoQ') {
          d1 = this.getQuarterStrings(1).toLowerCase();
          d2 = this.getQuarterStrings(2).toLowerCase();
          qry = "SELECT SUM(CASE WHEN date_trunc('qtr',month) = date_trunc('qtr',dateadd(qtr,-1,current_date)) THEN "+fld+" ELSE 0 END) as "+d1+", SUM(CASE WHEN date_trunc('qtr',month) = date_trunc('qtr',dateadd(qtr,-2,current_date)) THEN "+fld+" ELSE 0 END) as "+d2+" FROM analytics.monthly_metrics";
          //whr += "date_trunc('year',month) = date_trunc('year', (current_date  - interval '1 year'))";
        }
        
        qry += whr;
        //return false;
        this.queryRes = [];
        this.sqlTxt = '';
        this.errorMsg = '';

        this.loading = true;
        this.postSubmit = this.http.post<any>(environment.rsApiUrl, { 'querytxt': qry,'ptype':'sqlquery' }).subscribe(data => {
        
        this.sqlTxt = qry;
		    if(data.status == 'success' ) {
          //this.queryForm.reset();
          this.queryRes = data.output;
          
    // var myVariable = "28 Jan 2014"
    // var makeDate = new Date(myVariable);
    // makeDate = new Date(makeDate.setMonth(makeDate.getMonth() - 1));
    // console.log(makeDate);
    
          if(this.f.opt1.value == 'YoY') {
            this.queryRes[0]['percentage'] = (((parseInt(this.queryRes[0]['year'+d1]) - parseInt(this.queryRes[0]['year'+d2])) / parseInt(this.queryRes[0]['year'+d2])) * 100).toFixed(2);
          } else if(this.f.opt1.value == 'MoM' || this.f.opt1.value == 'QoQ') {
            this.queryRes[0]['percentage'] = (((parseInt(this.queryRes[0][d1]) - parseInt(this.queryRes[0][d2])) / parseInt(this.queryRes[0][d2])) * 100).toFixed(2);
          }
          console.log(this.queryRes);          
          this.changeData(this.queryRes, d1, d2);
          //$('#columnChart').show();
          $('#columnChart').css('visibility', 'visible');
        } else {
          this.errorMsg = data.error;
        }
        this.loading = false;
        
        
		});
	};

  public changeData(data, f1, f2): void {
    this.chartData = [];
    var lbl = '';
    if(this.f.opt1.value == 'CY') {
      lbl = 'Current Year';
      this.chartData.push({"name":lbl,"value":parseInt(data[0]['cnt']),"extra": {"type": "Year", "percentage": ''}});
    } else if(this.f.opt1.value == 'LY') {
      lbl = 'Last Year';
      this.chartData.push({"name":lbl,"value":parseInt(data[0]['cnt']),"extra": {"type": "Year", "percentage": ''}});
    } else if(this.f.opt1.value == 'YoY') {
      lbl = 'YoY';
      this.chartData.push({"name":f2,"value":parseInt(data[0]['year'+f2]),"extra": {"type": "Year", "percentage": ''}});
      this.chartData.push({'name':f1,"value":parseInt(data[0]['year'+f1]), "extra": {"type": "Year", "percentage": data[0]['percentage']}});  
      console.log(this.chartData);    
    } else if(this.f.opt1.value == 'MoM') {
      lbl = 'MoM';
      this.chartData.push({"name":f2.toUpperCase(),"value":parseInt(data[0][f2]),"extra": {"type": "Month", "percentage": ''}});
      this.chartData.push({'name':f1.toUpperCase(),"value":parseInt(data[0][f1]), "extra": {"type": "Month", "percentage": data[0]['percentage']}});   
    } else if(this.f.opt1.value == 'QoQ') {
      lbl = 'QoQ';
      console.log(f1);
      console.log(f2);
      this.chartData.push({"name":f2.toUpperCase(),"value":parseInt(data[0][f2]),"extra": {"type": "Quarter", "percentage": ''}});
      this.chartData.push({'name':f1.toUpperCase(),"value":parseInt(data[0][f1]), "extra": {"type": "Quarter", "percentage": data[0]['percentage']}}); 
    }
  }

  filterReport() {
    this.selSection = 'q2';
    console.log(this.f);
    this.http.post<any>(environment.apiUrl, { 'username': this.user.name,'ptype':'getTicket' }).subscribe(data => {
      if(data != -1) {
        this.initViz(data);
      }
    });
  }

  public initViz(t): void {
  	this.mService.emitChange(this.currMetrics);
		
		let mloader = this.el.nativeElement.querySelector("#loader");
  	mloader.classList.remove('d-none'); 
		let mview = this.el.nativeElement.querySelector("#viz-wrapper");
		mview.classList.add('d-none'); 


		const containerDiv = document.getElementById('tableauViz');
    var qry = '';
    var fld1 = '';
    var fld2 = ''; 

    if(this.f.opt6.value != '') {
      fld1 = this.f.opt6.value;
    }
    if(this.f.opt5.value != 'all' && this.f.opt5.value != '') {
      fld2 = this.f.opt6.value+this.f.opt5.value;
    }

     
		
		const vizUrl = environment.tableauUrl+'/trusted/'+t+'/views/Hippo-AnalyticsDashboard/'+this.f.opt4.value+'?:size=1232,850&:embed=y&:showVizHome=n&:bootstrapWhenNotified=y&:tabs=n&:toolbar=n&:apiID=host2'+qry;
    console.log(vizUrl);
    var options = {};
    if(this.f.opt4.value == 'DailyMetrics') {
      options = {
        hideTabs: true,
        width: '100%',
        height: '850px',
        "YEAR(Date)": fld1,
        "MY(Date)": fld2,
        onFirstInteractive: function() {
          mloader.classList.add('d-none'); 
          mview.classList.remove('d-none');
          // The viz is now ready and can be safely used.
        }
      };
    } else {
      options = {
        hideTabs: true,
        width: '100%',
        height: '850px',
        "YEAR(Month)": fld1,
        "MY(Month)": fld2,
        onFirstInteractive: function() {
          mloader.classList.add('d-none'); 
          mview.classList.remove('d-none');
          // The viz is now ready and can be safely used.
        }
      };
    }
    console.log(options);

		if (this.tableauViz) { // If a viz object exists, delete it.
			this.tableauViz.dispose();
		}

		//this.tableauViz = new tableau.Viz(placeholderDiv, url, options);	
		this.tableauViz = new Tableau.Viz(containerDiv, vizUrl, options);
  }


  onSubmitCancel() {
    this.postSubmit.unsubscribe();
    this.showQueryBox = true;
    this.queryRes = [];
    this.sqlTxt = '';
    this.errorMsg = '';
    this.loading = false;
  }

  exportData(etype) {
    if(etype == 'csv') {
      alasql.promise('SELECT * INTO CSV("export_data.csv", {asstring:true, headers:true, separator:","}) FROM ?',[this.queryRes])
      .then(function(){
           console.log('Data saved');
      }).catch(function(err){
           console.log('Error:', err);
      });
    } else if(etype == 'xlsx') {
      alasql.promise('SELECT * INTO XLSX("export_data.xlsx",{headers:true}) FROM ? ',[this.queryRes])
      .then(function(){
           console.log('Data saved');
      }).catch(function(err){
           console.log('Error:', err);
      });
    } else if(etype == 'txt') {
      alasql.promise('SELECT user_id,first_name INTO TXT("export_data.txt") FROM ? ',[this.queryRes])
      .then(function(){
           console.log('Data saved');
      }).catch(function(err){
           console.log('Error:', err);
      });
    }
  }

  exportD(content, etype) {
    this.exportType = etype;
    this.modalRef = this.modalService.open(content, { centered: true }).result.then((result) => {
      //this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      //this.closeResult = `Dismissed`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

}
