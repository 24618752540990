<!-- Sidebar menu-->
<div class="app-sidebar__overlay" data-toggle="sidebar"></div>
<aside class="app-sidebar">
   <div class="app-sidebar__user">
      <img class="app-sidebar__user-avatar" src="assets/images/48.jpg" alt="User Image">
      <div>
         <p class="app-sidebar__user-name">{{user?.fullName}}</p>
         <p class="app-sidebar__user-designation">
         <p class="username" style="margin-bottom:0px;">{{user?.name}}</p>
         <p></p>
      </div>
   </div>
   <!-- Tableau Menu -->
   <ul class="app-menu" *ngIf="sidebarObj.showSideMenu">
      <li><a class="app-menu__item active" routerLinkActive="active" routerLink="/Home/Dashboard"><i class="app-menu__icon fa fa-dashboard"></i><span class="app-menu__label">Dashboard</span></a></li>
      <li class="treeview" id="tmetrics_sm">
         <a class="app-menu__item" href="javascript://" (click)="tmenu('tmetrics_sm')" data-toggle="treeview"><i class="app-menu__icon fa fa-user"></i><span class="app-menu__label">Metrics</span><i class="treeview-indicator fa fa-angle-right"></i></a>
         <ul class="treeview-menu is-expanded">
            <li><a class="treeview-item" routerLinkActive="active" routerLink="/Home/LifetimeMetrics">Lifetime Metrics</a></li>
            <li><a class="treeview-item" routerLinkActive="active" routerLink="/Home/MonthlyMetrics">Monthly Metrics</a></li>
            <li><a class="treeview-item" routerLinkActive="active" routerLink="/Home/DailyMetrics">Daily Metrics</a></li>
            <li><a class="treeview-item" routerLinkActive="active" routerLink="/Home/MonthlySubs">Monthly Subs</a></li>
         </ul>
      </li>
      <li><a class="app-menu__item" routerLinkActive="active" routerLink="/Home/ActiveUsers"><i class="app-menu__icon fa fa-user-circle"></i><span class="app-menu__label">Active Users</span></a></li>
      <li><a class="app-menu__item" routerLinkActive="active" routerLink="/Home/NewSubs"><i class="app-menu__icon fa fa-tasks"></i><span class="app-menu__label">New Subs</span></a></li>
      <li><a class="app-menu__item" routerLinkActive="active" routerLink="/Home/TotalSubs"><i class="app-menu__icon fa fa-file"></i><span class="app-menu__label"> Total Subs</span></a></li>
      <li><a class="app-menu__item" routerLinkActive="active" routerLink="/Home/EngagementDetail"><i class="app-menu__icon fa fa-th"></i><span class="app-menu__label">Engagement</span></a></li>
      <li><a class="app-menu__item" routerLinkActive="active" routerLink="/Home/Month1Retention"><i class="app-menu__icon fa fa-calendar"></i><span class="app-menu__label">Month 1 Retention</span></a></li>
   </ul>

   <!-- Sql query Menu  *ngIf="sidebarObj.showSqlMenu" -->
   <ul class="app-menu" *ngIf="sidebarObj.showSqlMenu">
      <li><a class="app-menu__item active" href="javascript:void(0)" routerLinkActive="active" [routerLink]="['/SQLQuery']"><i class="app-menu__icon fa fa-code"></i><span class="app-menu__label">Sql Query</span></a></li>
      <li *ngIf="menuRes.length == 0">
        <a class="app-menu__item active" href="javascript:void(0)" (click)="getSideMenu('init')"><i class="app-menu__icon fa fa-plug"></i><span class="app-menu__label">Connect to Database</span></a>
      </li>
      <li class="treeview" id="{{mrow.key}}" *ngFor="let mrow of menuRes | keyvalue">
         <a class="app-menu__item" href="javascript://" (click)="tmenu(mrow.key)"  data-toggle="treeview"><i class="app-menu__icon fa fa-database"></i><span class="app-menu__label">{{mrow.key}}</span><i class="treeview-indicator fa fa-angle-right"></i></a>
         <ul class="treeview-menu">
            <li *ngFor="let smrow of mrow.value; let i = index"><a href="javascript:void(0)" class="treeview-item" routerLinkActive="active" routerLinkActive="active" [routerLink]="['/SQLQuery', 'list', smrow.table_schema, smrow.table_name]" [contextMenu]="basicMenu" [contextMenuSubject]="smrow">{{smrow.table_name}}</a></li>
         </ul>
      </li>
   </ul>
   <!-- <div id="loader" class="fa-3x text-center text-white" *ngIf="loading && (sidebarObj.showSqlMenu || sidebarObj.showRSSqlMenu)">
      <i class="fas fa-spinner fa-spin"></i>
   </div> -->
   <div id="loader" class="fa-3x text-center text-white" *ngIf="loading && (sidebarObj.showSFMenu || sidebarObj.showMySqlMenu || sidebarObj.showSqlMenu || sidebarObj.showRSSqlMenu)">
      <i class="fas fa-spinner fa-spin"></i>
   </div>

   <!-- Admin Menu -->
   <ul class="app-menu" *ngIf="sidebarObj.showAdminMenu">
      <li><a class="app-menu__item active" routerLinkActive="active" routerLink="/Admin/Settings"><i class="app-menu__icon fa fa-dashboard"></i><span class="app-menu__label">Settings</span></a></li>
      <li class="treeview" id="tableau_sm">
         <a class="app-menu__item" href="javascript://" (click)="tmenu('tableau_sm')" data-toggle="treeview"><i class="app-menu__icon fa fa-user"></i><span class="app-menu__label">Tableau</span><i class="treeview-indicator fa fa-angle-right"></i></a>
         <ul class="treeview-menu">
            <li><a class="treeview-item" routerLinkActive="active" routerLink="/Admin/Users">Users</a></li>
            <li><a class="treeview-item" routerLinkActive="active" routerLink="/Admin/Groups">Groups</a></li>
            <li><a class="treeview-item" routerLinkActive="active" routerLink="/Admin/Projects">Project</a></li>
         </ul>
      </li>
   </ul>

   <!-- Product Menu -->
   <ul class="app-menu" *ngIf="sidebarObj.showProdMenu">
      <li><a class="app-menu__item" routerLinkActive="active" routerLink="/Product/SubsbyProductTable"><i class="app-menu__icon fa fa-dashboard"></i><span class="app-menu__label">Subs by Product Table</span></a></li>
      <li><a class="app-menu__item" routerLinkActive="active" routerLink="/Product/SubsbyProductChart"><i class="app-menu__icon fa fa-user-circle"></i><span class="app-menu__label">Subs by Product Chart</span></a></li>
      <li><a class="app-menu__item" routerLinkActive="active" routerLink="/Product/ChurnRateTable"><i class="app-menu__icon fa fa-tasks"></i><span class="app-menu__label">Churn Rate Table</span></a></li>
      <li><a class="app-menu__item" routerLinkActive="active" routerLink="/Product/ChurnRatebyYear"><i class="app-menu__icon fa fa-file"></i><span class="app-menu__label">Churn Rate by Year</span></a></li>
      <li><a class="app-menu__item" routerLinkActive="active" routerLink="/Product/ChurnRatebyMonth"><i class="app-menu__icon fa fa-th"></i><span class="app-menu__label">Churn Rate by Month</span></a></li>
      <li><a class="app-menu__item" routerLinkActive="active" routerLink="/Product/FreetoPaidConversion"><i class="app-menu__icon fa fa-calendar"></i><span class="app-menu__label">Free to Paid Conversion</span></a></li>
   </ul>

   <!-- PR Menu -->
   <ul class="app-menu" *ngIf="sidebarObj.showPRMenu">
      <li><a class="app-menu__item" routerLinkActive="active" routerLink="/Partner-Reporting/AAPASalesTransaction"><i class="app-menu__icon fa fa-dashboard"></i><span class="app-menu__label">AAPA Sales Transactions</span></a></li>
      <li><a class="app-menu__item" routerLinkActive="active" routerLink="/Partner-Reporting/AAPAGPSAQuiz"><i class="app-menu__icon fa fa-user-circle"></i><span class="app-menu__label">AAPA GP SA Quiz</span></a></li>
      <li><a class="app-menu__item" routerLinkActive="active" routerLink="/Partner-Reporting/AAPAQbankQuiz"><i class="app-menu__icon fa fa-tasks"></i><span class="app-menu__label">AAPA Qbank Quiz</span></a></li>
      <li><a class="app-menu__item" routerLinkActive="active" routerLink="/Partner-Reporting/AAPAPCRapQuiz"><i class="app-menu__icon fa fa-file"></i><span class="app-menu__label">AAPA PC RAP Quiz</span></a></li>
   </ul>

   <!-- Dataroom Menu -->
   <ul class="app-menu" *ngIf="sidebarObj.showDroomMenu">
      <li><a class="app-menu__item" routerLinkActive="active" routerLink="/Dataroom/Glossary"><i class="app-menu__icon fa fa-user-circle"></i><span class="app-menu__label">Glossary</span></a></li>
      <li><a class="app-menu__item" routerLinkActive="active" routerLink="/Dataroom/Lifetime-Metrics"><i class="app-menu__icon fa fa-user-circle"></i><span class="app-menu__label">Lifetime Metrics</span></a></li>
      <li><a class="app-menu__item" routerLinkActive="active" routerLink="/Dataroom/Monthly-Metrics"><i class="app-menu__icon fa fa-file"></i><span class="app-menu__label">Monthly Metrics</span></a></li>
      <li><a class="app-menu__item" routerLinkActive="active" routerLink="/Dataroom/SubsbyProduct-Table"><i class="app-menu__icon fa fa-th"></i><span class="app-menu__label">Customers by Vertical</span></a></li>
      <li><a class="app-menu__item" routerLinkActive="active" routerLink="/Dataroom/SubsbyCountry"><i class="app-menu__icon fa fa-globe"></i><span class="app-menu__label">Customers by Geography</span></a></li>
      <li><a class="app-menu__item" routerLinkActive="active" routerLink="/Dataroom/SubsbyProfession"><i class="app-menu__icon fa fa-medkit"></i><span class="app-menu__label">Customers by Profession</span></a></li>
      <li><a class="app-menu__item" routerLinkActive="active" routerLink="/Dataroom/Conversion-Rates"><i class="app-menu__icon fa fa-tasks"></i><span class="app-menu__label">Conversion Rates</span></a></li>
      <!-- <li><a class="app-menu__item" routerLinkActive="active" routerLink="/Dataroom/ChurnRatebyMonth-Table"><i class="app-menu__icon fa fa-calendar"></i><span class="app-menu__label">Churn</span></a></li> -->
      <li><a class="app-menu__item" routerLinkActive="active" routerLink="/Dataroom/LTVLifetimes"><i class="app-menu__icon fa fa-dashboard"></i><span class="app-menu__label">LTV/Lifetimes</span></a></li>
   </ul>
</aside>



<context-menu class="">
  <ng-template contextMenuItem (execute)="showTable($event.item, 'structure')">
    Open Structure
  </ng-template>
  <ng-template contextMenuItem divider="true"></ng-template>
  <ng-template contextMenuItem let-item (execute)="showTable($event.item, 'list')">
    Open Contents
  </ng-template>
  <!-- <ng-template contextMenuItem passive="true">
    Input something: <input type="text">
  </ng-template> -->
</context-menu>