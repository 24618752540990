// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  	production: true,
    // apiUrl: 'http://localhost/analytics-portal/api/tapi.php',
    // rsApiUrl: 'http://localhost/analytics-portal/api/rsapi.php',
    // configApiUrl: 'http://localhost/analytics-portal/api/saveconfig.php',
    // ApiPath: 'http://localhost/analytics-portal/api/',
    apiUrl: '/api/tapi.php',
    rsApiUrl: '/api/rsapi.php',
    configApiUrl: '/api/saveconfig.php',
    ApiPath: '/api/',
    tableauUrl: 'https://visualization.datonique.com',
    sessionTime: 900,
    sessionStay: 10
};

/*
 * apiUrl: 'http://localhost/tableau/tapi.php'
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
