<!-- Navbar-->
<header class="app-header">
   <a class="app-header__logo" href="index.html" style="margin-top:-8px;"><img src="assets/images/logo_small.png" alt="" /></a>
   <!-- Sidebar toggle button-->
   <a class="app-sidebar__toggle" href="javascript://" data-toggle="sidebar" aria-label="Hide Sidebar" (click)="toggleMenu()"></a>
   <div>
      <nav class="navbar navbar-expand-lg navbar-light">
         <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" style="outline:none; border:none;">
         <span class="navbar-toggler-icon1"><i class="fa fa-bars"></i></span>
         </button>
         <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav">
               <li class="nav-item">
                  <a class="nav-link" href="javascript://" routerLinkActive="active" routerLink="/Home" *ngIf="!isDararoom">Home</a>
               </li>
               <li class="nav-item">
                  <a class="nav-link" href="javascript://" routerLinkActive="active" routerLink="/Product" *ngIf="!isDararoom">Product</a>
               </li>
               <li class="nav-item">
                  <a class="nav-link" href="javascript://" routerLinkActive="active" routerLink="/Partner-Reporting" *ngIf="!isDararoom">Partner Reporting</a>
               </li>
               <li class="nav-item">
                  <a class="nav-link" href="javascript://" data-toggle="dropdown" aria-label="Resources Menu" *ngIf="!isDararoom">Resources</a>
                   <ul class="dropdown-menu settings-menu dropdown-menu-right">
                      <li><a class="dropdown-item" href="https://docs.google.com/spreadsheets/d/1yvp31dWg-iTFbCBmjx_zQ_YjLAsBE6qBWzOxcD5ZG3A/edit#gid=0" target="_blank"> Metrics Definitions</a></li>
                      <li><a class="dropdown-item" href="https://docs.google.com/spreadsheets/d/1yvp31dWg-iTFbCBmjx_zQ_YjLAsBE6qBWzOxcD5ZG3A/edit#gid=1983327631" target="_blank"> Analytics SDK Events Specifications</a></li>
                      <li><a class="dropdown-item" href="https://docs.google.com/spreadsheets/d/1SOBXXCklS03J_XkAZxgWfJHs4PKJmaawLGU3Y4NKfEo/edit#gid=334536491" target="_blank"> Analytics SDK QA Master Sheet</a></li>
                      <li><a class="dropdown-item" href="https://docs.google.com/spreadsheets/d/1BLaNZPnylZW2uy-i4nU4S_G5n2FjkzJDhJpK8L62voM/edit#gid=0" target="_blank"> HubSpot Data Master Sheet</a></li>
                   </ul>
               </li>
               <li class="nav-item">
                  <a class="nav-link" href="javascript://" routerLinkActive="active" routerLink="/SQLQuery" *ngIf="!isDararoom">SQL Query</a>
               </li>
               <!-- <li class="nav-item">
                  <a class="nav-link" href="javascript://" routerLinkActive="active" routerLink="/Analytics" *ngIf="!isDararoom">Analytics</a>
               </li> -->
               <li class="nav-item">
                  <a class="nav-link" href="javascript://" routerLinkActive="active" routerLink="/Admin" *ngIf="isAdmin">Admin</a>
               </li>
               <li class="nav-item">
                  <a class="nav-link" href="javascript://" routerLinkActive="active" routerLink="/Dataroom">Dataroom</a>
               </li>
            </ul>
         </div>
      </nav>
   </div>
   <!-- Navbar Right Menu-->
   <ul class="app-nav">
      <!--Notification Menu-->
      <!-- User Menu-->
      <li class="dropdown">
         <a class="app-nav__item" href="#" data-toggle="dropdown" aria-label="Open Profile Menu"><i class="fa fa-user fa-lg"></i></a>
         <ul class="dropdown-menu settings-menu dropdown-menu-right">
            <li><a class="dropdown-item" href="javascript://" (click)="open(mymodal)"><i class="fa fa-key fa-lg"></i> Change Password</a></li>
            <li><a class="dropdown-item" href="javascript://" (click)="logout()"><i class="fa fa-sign-out fa-lg"></i> Logout</a></li>
         </ul>
      </li>
   </ul>
</header>

<ng-template #mymodal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Change Password </h4>
        <button type="button" class="close" aria-label="Close" #closebutton (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body" [formGroup]="changepwd_form">
        <div class="form-group">
            <label for="updatePassword1">Password</label>
            <input type="password" autocomplete="new-password" class="form-control" id="newpassword1" placeholder="New Password" formControlName="newpassword1">
        </div>
        <div class="form-group">
            <label for="updatePassword2">Confirm Password</label>
            <input type="password" autocomplete="new-password" class="form-control" id="newpassword2" placeholder="Confirm Password" formControlName="newpassword2">
        </div>
    </div>
    <div class="alert alert-success hidden" role="alert" *ngIf="chgPwdMsgshow">
        New password updated successfully.
    </div>
    <div class="modal-footer">
        <button type="button" id="updatePasswordSaveButton" class="btn btn-primary" (click)="submitForm()">Save</button>
        <button type="button" class="btn btn-secondary btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
    </div>
</ng-template>