import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as jwt_decode from 'jwt-decode';

import { environment } from '@environments/environment';
import { User } from '@app/_models';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
    private userSubject: BehaviorSubject<User>;
    public user: Observable<User>;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        var decoded = this.getDecodedAccessToken(localStorage.getItem('user'));
        this.userSubject = new BehaviorSubject<User>(decoded);
        this.user = this.userSubject.asObservable();
    }

    getDecodedAccessToken(token: string): any {
        try{
            return jwt_decode(token);
        }
        catch(Error){
            return null;
        }
    }

    public get userValue(): User {
        return this.userSubject.value;
    }

    login(username: string, password: string, ptype: string) {
        return this.http.post<any>(`${environment.apiUrl}`, { username, password, ptype })
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                //localStorage.setItem('user', JSON.stringify(user));
                //this.userSubject.next(user);
                if(user !== "error") {
                    localStorage.setItem('user', JSON.stringify(user));
                    localStorage.setItem('access_token', 'yes');
                    var decoded = this.getDecodedAccessToken(user);
                    this.userSubject.next(decoded);
                }
                return user;
            }));
    }

    public isLoggedIn(){
        return localStorage.getItem('access_token') !== null;
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('user');
        localStorage.removeItem('access_token');
        this.userSubject.next(null);
    }
}