import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dataroom',
  templateUrl: './dataroom.component.html',
  styleUrls: ['./dataroom.component.css']
})
export class DataroomComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
