<main class="app-content">
   <div class="app-title">
      <div>
         <h1><i class="fa fa-code"></i> 404</h1>
      </div>
   </div>
   <div class="row">
      <div class="col-md-12">
         <div class="tile">
            <div class="embed-responsive">
                    <div class="col-md-12">
                        <div class="error-template" style="text-align: center;">
                            <h1>
                                Oops!</h1>
                            <h2>
                                404 Not Found</h2>
                            <div class="error-details">
                                Sorry, an error has occured, Requested page not found!
                            </div>
                            <div class="error-actions">
                                <a href="javascript://" routerLinkActive="active" routerLink="/Dataroom" class="btn btn-primary btn-lg"><span class="glyphicon glyphicon-home"></span>
                                    Take Me Home </a>
                            </div>
                        </div>
                    </div>
            </div>
         </div>
      </div>
   </div>
   
</main>
