<main class="app-content">
   <div class="app-title">
      <div>
         <h1><i class="fa fa-dashboard"></i> {{selMetrics.displayname}}</h1>
      </div>
      <ul class="app-breadcrumb breadcrumb">
         <li class="breadcrumb-item dropdown"><a href="#" data-toggle="dropdown" aria-label="Open Profile Menu"><i class="fa fa-cloud-download fa-lg"  data-toggle="tooltip" data-placement="bottom" title="Download"></i></a>
            <ul class="dropdown-menu settings-menu dropdown-menu-right">
            <li><a class="dropdown-item" href="javascript://" (click)="tableauProcess('pdf')"><i class="fa fa-file-pdf-o fa-lg"></i> Pdf</a></li>
               <li><a class="dropdown-item" href="javascript://" (click)="tableauProcess('image')"><i class="fa fa-image fa-lg"></i> Image</a></li>
               <li><a class="dropdown-item" href="javascript://" (click)="tableauProcess('loaddata')"><i class="fa fa-database fa-lg"></i> Data</a></li>
               <li><a class="dropdown-item" href="javascript://" (click)="tableauProcess('crosstab')"><i class="fa fa-th-large fa-lg"></i> CrossTab</a></li>
            </ul>
         </li>
         <li class="breadcrumb-item dropdown">
            <a href="#" data-toggle="dropdown" data-placement="bottom" title="Share" aria-label="Open Profile Menu"><i class="fa fa-share-alt fa-lg" data-toggle="tooltip" data-placement="bottom" title="Share"></i></a>
            <ul class="dropdown-menu settings-menu dropdown-menu-right">
               <li>
                  <a class="dropdown-item" href="{{shareLink}}" data-dom-for="share" style="text-transform: none;">{{shareLink}}</a>
                    <span class="badge badge-secondary" style="float: right; margin-right: 10px; margin-bottom:2px; cursor: pointer;" (click)="copyText(shareLink)">Copy </span>
               </li>
            </ul>
         </li>
         <li class="breadcrumb-item"><a href="javascript://" (click)="tableauProcess('undo')" data-toggle="tooltip" data-placement="bottom" title="Undo"><i class="fa fa-undo fa-lg"></i></a></li>
         <li class="breadcrumb-item"><a href="javascript://" (click)="tableauProcess('redo')" data-toggle="tooltip" data-placement="bottom" title="Redo"><i class="fa fa-repeat fa-lg"></i></a></li>
         <li class="breadcrumb-item"><a href="javascript://" (click)="tableauProcess('revert')" data-toggle="tooltip" data-placement="bottom" title="Revert"><i class="fa fa-retweet fa-lg"></i></a></li>
         <li class="breadcrumb-item"><a href="javascript://" (click)="tableauProcess('refresh')" data-toggle="tooltip" data-placement="bottom" title="Refresh"><i class="fa fa-refresh fa-lg"></i></a></li>
         <li class="breadcrumb-item d-none" id="resume"><a href="javascript://" (click)="tableauProcess('resume')"  data-toggle="tooltip" data-placement="bottom" title="Resume"><i class="fa fa-play fa-lg"></i></a></li>
         <li class="breadcrumb-item" id="pause"><a href="javascript://" (click)="tableauProcess('pause')" data-toggle="tooltip" data-placement="bottom" title="Pause"><i class="fa fa-pause fa-lg"></i></a></li>
      </ul>
   </div>
   <div class="row">
      <div class="col-md-12">
         <div class="tile" style="padding: 2px;">
            <!-- <h3 class="tile-title">Overall Metrics - September 2020</h3> -->
            <div class="embed-responsive">
               <section style="display: none;" id="no-views" class="hidden">
                  <div class="container sidebar-container my-3">
                     <h5>No views found on the Tableau Server. Please ensure your user has permission to see the views.</h5>
                  </div>
               </section>
               <section class="bg-white content-wrapper">
                  <div id="loader" class="hidden text-center" style="padding: 30px;">
                     <i class="fa fa-spinner fa-spin fa-4x fa-fw"></i>
                  </div>
                  <div class="viz-wrapper py-3" id="viz-wrapper" style=" height: 850px;">
                     <div id="tableauViz" style="width: 100%;" class="h-100 d-inline-block"></div>
                  </div>
               </section>
            </div>
         </div>
      </div>
   </div>
</main>